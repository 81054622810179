import * as React from "react";
import { formCol, formColFlex } from "./Form.module.scss";
import { className } from "utils/className";
interface FormColumnProps {
  children?: React.ReactNode;
  flex?: boolean;
}

export function FormColumn(props: FormColumnProps) {
  return (
    <div className={className(formCol, props.flex && formColFlex)}>
      {props.children}
    </div>
  );
}
