import * as React from "react";
import { formRow } from "./Form.module.scss";

interface FormRowProps {
  children?: React.ReactNode;
}

export function FormRow(props: FormRowProps) {
  return <div className={formRow}>{props.children}</div>;
}
