import * as React from "react";

import { formContent } from "./Form.module.scss";

interface FormContentProps {
  children?: React.ReactNode;
}

export function FormContent(props: FormContentProps) {
  return <div className={formContent}>{props.children}</div>;
}
