import * as React from "react";
import { Link } from "gatsby";

import { button, danger, disabled } from "./Button.module.scss";

interface ButtonProps {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  danger?: boolean;
  className?: string;
}

export function Button(props: ButtonProps) {
  if (props.to) {
    return (
      <Link
        to={props.to}
        className={[
          button,
          props.disabled ? disabled : "",
          props.danger ? danger : "",
          props.className ? props.className : "",
        ].join(" ")}
      >
        {props.children}
      </Link>
    );
  }

  return (
    <button
      className={[
        button,
        props.disabled ? disabled : "",
        props.danger ? danger : "",
        props.className ? props.className : "",
      ].join(" ")}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
