import React from "react";

import { navigate } from "gatsby";

import { SEO } from "components/common/SEO";

import { Container, Layout } from "components/layout";
import {
  Form,
  FormTitle,
  FormTextInput,
  FormFooter,
  FormContent,
  FormRow,
  FormColumn,
  FormButton,
} from "components/common/Form";

import { ButtonLink } from "components/common/Button";
import { ViewHeader } from "components/view/ViewHeader";
import { useRegister } from "hooks/api";

export default function RegisterPage() {
  const register = useRegister();

  React.useEffect(() => {
    register?.cleanErrors();
  }, []);

  const handleSubmit = async (data: FormData) => {
    register?.cleanErrors();
    await register?.process(data).then((success) => {
      if (success) navigate("/login");
    });
  };

  return (
    <Layout fullscreen dark>
      <SEO title="Register" />
      <ViewHeader dark />
      <Container className="login">
        <Form centered dark onSubmit={handleSubmit}>
          <FormTitle>Create your RESTGIS account</FormTitle>
          <FormContent>
            <FormRow>
              <FormColumn>
                <FormTextInput
                  field="first_name"
                  label="First Name"
                  error={register?.errors?.["first_name"]?.[0]}
                  formatError={(error) => {
                    switch (error) {
                      case "This field may not be blank.":
                        return "Enter your first name.";
                      default:
                        return error;
                    }
                  }}
                />
              </FormColumn>
              <FormColumn>
                <FormTextInput
                  field="last_name"
                  label="Last Name"
                  error={register?.errors?.["last_name"]?.[0]}
                  formatError={(error) => {
                    switch (error) {
                      case "This field may not be blank.":
                        return "Enter your last name.";
                      default:
                        return error;
                    }
                  }}
                />
              </FormColumn>
            </FormRow>
            <FormTextInput
              field="username"
              label="Username"
              error={register?.errors?.["username"]?.[0]}
              formatError={(error) => {
                switch (error) {
                  case "This field may not be blank.":
                    return "Enter your new username.";
                  default:
                    return error;
                }
              }}
            />
            <FormTextInput
              type="email"
              field="email"
              label="Email"
              error={register?.errors?.["email"]?.[0]}
              formatError={(error) => {
                switch (error) {
                  case "This field may not be blank.":
                    return "Enter your email address.";
                  default:
                    return error;
                }
              }}
            />
            <FormRow>
              <FormColumn>
                <FormTextInput
                  field="password"
                  type="password"
                  label="Password"
                  error={register?.errors?.["password"]?.[0]}
                  formatError={(error) => {
                    switch (error) {
                      case "This field may not be blank.":
                        return "Enter your new password.";
                      default:
                        return error;
                    }
                  }}
                />
              </FormColumn>
              <FormColumn>
                <FormTextInput
                  field="repassword"
                  type="password"
                  label="Confirm Password"
                  error={register?.errors?.["repassword"]?.[0]}
                  formatError={(error) => {
                    switch (error) {
                      case "This field may not be blank.":
                        return "Enter your new password.";
                      default:
                        return error;
                    }
                  }}
                />
              </FormColumn>
            </FormRow>
          </FormContent>
          <FormFooter>
            <FormButton>Create Account</FormButton>
            <ButtonLink to="/login" label="Log in" />
          </FormFooter>
        </Form>
      </Container>
    </Layout>
  );
}
