import * as React from "react";

import { CircleCheckIcon } from "components/Icons";

import { formSuccessLabel } from "./Form.module.scss";

export function FormSuccessLabel(props: React.PropsWithChildren<{}>) {
  return (
    <label className={formSuccessLabel}>
      <CircleCheckIcon />
      <div>{props.children}</div>
    </label>
  );
}
