import * as React from "react";
import {
  formCheckboxInput,
  formCheckboxInputError,
  formInputDisabled,
} from "./Form.module.scss";

interface FormCheckboxInputProps {
  field: string;
  label: string;
  error?: string;
  formatError?: (value: string) => string;
  disabled?: boolean;

  defaultChecked?: boolean;
}

export function FormCheckboxInput(props: FormCheckboxInputProps) {
  const { error } = props;
  const [displayError, setDisplayError] = React.useState(true);

  const formatError = (e: string) => {
    if (props.formatError) return props.formatError(e);
    switch (e) {
      default:
        return e;
    }
  };

  React.useEffect(() => {
    setDisplayError(true);
  }, [error]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDisplayError(false);
    },
    []
  );

  const Component = React.useMemo(() => {
    return (
      _props: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    ) => <input type={"checkbox"} {..._props} />;
  }, []);

  return (
    <div
      className={[
        formCheckboxInput,
        props.disabled ? formInputDisabled : "",
      ].join(" ")}
    >
      <label>{props.label}</label>
      <Component
        name={props.field}
        defaultChecked={props.defaultChecked}
        onChange={handleChange}
        disabled={props.disabled}
      />
      {error && displayError ? (
        <label className={formCheckboxInputError}>{formatError(error)}</label>
      ) : null}
    </div>
  );
}
