import * as React from "react";
import { formTitle } from "./Form.module.scss";

interface FormTitleProps {
  children?: React.ReactNode;
}

export function FormTitle(props: FormTitleProps) {
  return <h1 className={formTitle}>{props.children}</h1>;
}
