import * as React from "react";
import { form, dark, centered } from "./Form.module.scss";

interface FormProps extends React.PropsWithChildren<{}> {
  dark?: boolean;
  centered?: boolean;

  maxWidth?: string | number;

  onSubmit?: (data: FormData) => void;
}

export function Form(props: FormProps) {
  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      props.onSubmit?.(new FormData(e.nativeEvent.target as HTMLFormElement));
    },
    []
  );

  return (
    <form
      className={[
        form,
        props.dark ? dark : "",
        props.centered ? centered : "",
      ].join(" ")}
      onSubmit={handleSubmit}
      style={{
        maxWidth: props.maxWidth,
      }}
    >
      {props.children}
    </form>
  );
}
