import * as React from "react";
import { Button } from "components/common/Button";
import { formButton } from "./Form.module.scss";

interface ButtonProps {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  transparent?: boolean;
  danger?: boolean;
  className?: string;
}

export function FormButton(props: ButtonProps) {
  return (
    <Button {...props} className={[formButton, props.className].join(" ")} />
  );
}
