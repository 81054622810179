import * as React from "react";

import { formButtonLink } from "./Form.module.scss";
import { ButtonLink } from "components/common/Button";

interface ButtonProps {
  to?: string;
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  transparent?: boolean;

  className?: string;
}

export function FormButtonLink(props: ButtonProps) {
  return <ButtonLink {...props} className={formButtonLink} />;
}
