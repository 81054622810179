// extracted by mini-css-extract-plugin
export var centered = "Form-module--centered--q3Jxx";
export var dark = "Form-module--dark--jzHgn";
export var form = "Form-module--form--uCFpS";
export var formButton = "Form-module--formButton--d0fWh";
export var formButtonLink = "Form-module--formButtonLink--4cDTl";
export var formCheckboxInput = "Form-module--formCheckboxInput--qIFs4";
export var formCheckboxInputError = "Form-module--formCheckboxInputError--0crs7";
export var formCol = "Form-module--formCol--9Ni-F";
export var formColFlex = "Form-module--formColFlex---Cp80";
export var formContent = "Form-module--formContent---0ztr";
export var formFooter = "Form-module--formFooter--AcUD4";
export var formHeader = "Form-module--formHeader--NIBff";
export var formInputDisabled = "Form-module--formInputDisabled--qf2HA";
export var formRow = "Form-module--formRow--jw-hg";
export var formSuccessLabel = "Form-module--formSuccessLabel--E+t0K";
export var formTextInput = "Form-module--formTextInput--Oti4e";
export var formTextInputError = "Form-module--formTextInputError--jOpa9";
export var formTitle = "Form-module--formTitle--Tr3z6";