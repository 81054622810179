import * as React from "react";
import { formFooter } from "./Form.module.scss";

interface FormFooterProps {
  children?: React.ReactNode;
}

export function FormFooter(props: FormFooterProps) {
  return <div className={formFooter}>{props.children}</div>;
}
